<template>
  <div>
    <!-- Приветственная секция -->
    <WelcomeSection />

    <!-- Секция "О нас" -->
    <section class="about">
      <div class="about-content">
        <div class="about-text">
          <h2>{{ $t('whoWeAre') }}</h2>
          <p><strong>{{ $t('clientTrust') }}</strong></p>
          <p>{{ $t('experienceOverview') }}</p>
        </div>
        <img src="@/assets/about-image.png" alt="About us" class="about-image" />
      </div>
    </section>

    <!-- Секция "Наши услуги" -->
    <section class="services">
      <div class="service-container">
        <h2>{{ $t('services') }}</h2>
        <div class="service-items">
          <div class="service-item">
            <img src="@/assets/service1.png" :alt="$t('service1Title')" class="service-icon" />
            <h3>{{ $t('service1Title') }}</h3>
            <p>{{ $t('service1Desc') }}</p>
          </div>
          <div class="service-item">
            <img src="@/assets/service2.png" :alt="$t('service2Title')" class="service-icon" />
            <h3>{{ $t('service2Title') }}</h3>
            <p>{{ $t('service2Desc') }}</p>
          </div>
          <div class="service-item">
            <img src="@/assets/service3.png" :alt="$t('service3Title')" class="service-icon" />
            <h3>{{ $t('service3Title') }}</h3>
            <p>{{ $t('service3Desc') }}</p>
          </div>
          <div class="service-item">
            <img src="@/assets/service4.png" :alt="$t('service4Title')" class="service-icon" />
            <h3>{{ $t('service4Title') }}</h3>
            <p>{{ $t('service4Desc') }}</p>
          </div>
          <div class="service-item">
            <img src="@/assets/service5.png" :alt="$t('service5Title')" class="service-icon" />
            <h3>{{ $t('service5Title') }}</h3>
            <p>{{ $t('service5Desc') }}</p>
          </div>
          <div class="service-item">
            <img src="@/assets/service6.png" :alt="$t('service6Title')" class="service-icon" />
            <h3>{{ $t('service6Title') }}</h3>
            <p>{{ $t('service6Desc') }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import WelcomeSection from '@/components/WelcomeSection.vue';

export default {
  components: {
    WelcomeSection,
  },
};
</script>

<style scoped>
.about, .services {
  margin-top: 20px;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.about-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.about-text {
  max-width: 600px;
  margin-right: 20px;
}

.about-text p {
  line-height: 1.6;
  margin-bottom: 15px;
}

.about-image {
  width: 100%; /* Картинка станет адаптивной */
  max-width: 400px; /* Ограничиваем максимальную ширину картинки */
  height: auto; /* Сохраняем пропорции изображения */
  border-radius: 10px; /* Добавим немного округлых углов для стиля */
  object-fit: cover; /* Гарантируем, что изображение будет корректно отображаться */
}

.service-container {
  max-width: 1200px;
  margin: 0 auto;
}

.service-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Карточки будут адаптивно занимать 250px */
  grid-gap: 20px; /* Пробелы между карточками */
}

.service-item {
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.service-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
}

.service-item h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.service-item p {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .about-text {
    max-width: 100%; /* Убираем ограничение для текста */
    margin-right: 0;
    margin-bottom: 20px; /* Добавляем отступ снизу для текстового блока */
  }

  .about-image {
    max-width: 100%; /* Картинка займет 100% ширины для мобильных устройств */
  }

  .service-items {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Уменьшаем ширину карточек на мобильных устройствах */
  }

  .service-item {
    padding: 15px;
  }

  .service-icon {
    width: 50px;
    height: 50px;
  }

  .service-item h3 {
    font-size: 16px;
  }

  .service-item p {
    font-size: 14px;
  }
}
</style>
