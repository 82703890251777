<template>
  <div class="contacts">
    <div class="hero">
      <h1>{{ $t('contactTitle') }}</h1>
      <p>{{ $t('contactSubtitle') }}</p>
    </div>

    <div class="contact-info">
      <div class="contact-item">
        <h2>{{ $t('address') }}</h2>
        <p>Пресненская наб., Москва, 123317</p>
      </div>

      <div class="contact-item">
        <h2>{{ $t('phone') }}</h2>
        <p>+7 (985) 882-95-97</p>
      </div>

      <div class="contact-item">
        <h2>{{ $t('email') }}</h2>
        <p>info@corp-marketing-solution.com</p>
      </div>

      <div class="contact-item">
        <h2>{{ $t('telegram') }}</h2>
        <p><a href="https://t.me/Marketing_solution_work" target="_blank">{{ $t('contactViaTelegram') }}</a></p>
      </div>      

      <div class="contact-item">
        <h2>{{ $t('whatsapp') }}</h2>
        <p><a href="https://wa.me/+79858829597" target="_blank">{{ $t('contactViaWhatsapp') }}</a></p>
      </div>
    </div>

    <div class="map">
      <h2>{{ $t('ourLocation') }}</h2>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2246.7037892569027!2d37.53282731576036!3d55.74871709936648!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b54bb731ecb52b%3A0x8308ff7b4144b835!2z0KLQvtC70YzQvdCw0Y8g0J_QvtC70L7Rh9GC0YDQvtGB0LrQuNC5INGB0L7QsdCw0L3QuNGPINGB0L7QsdC10YDQsNC9LCDQnNC-0YHQutCy0LAsIDEyMzMxNw!5e0!3m2!1sru!2sru!4v1692286039583!5m2!1sru!2sru" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'ContactsView',
};
</script>

<style scoped>
.contacts {
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.hero {
  background: linear-gradient(135deg, #1c315a, #091729);
  color: white;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 30px;
}

.contact-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contact-item {
  flex: 1 1 30%;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.contact-item h2 {
  color: #333;
  margin-bottom: 15px;
}

.contact-item p {
  color: #666;
  line-height: 1.6;
}

.contact-item a {
  color: #007bff;
  text-decoration: none;
}

.contact-item a:hover {
  text-decoration: underline;
}

.map {
  margin-top: 40px;
  text-align: center;
}

.map h2 {
  color: #333;
  margin-bottom: 20px;
}
</style>
