<template>
  <header class="glass-header">
    <div class="logo-container">
      <img src="@/assets/marketing-solution-logo.png" alt="Marketing Solution Logo" class="logo" />
      <h1>Marketing Solution</h1>
    </div>
    <nav>
      <ul class="desktop-menu">
        <li><router-link to="/">{{ $t('home') }}</router-link></li>
        <li><router-link to="/about">{{ $t('about') }}</router-link></li>
        <li><router-link to="/services">{{ $t('services') }}</router-link></li>
        <li><router-link to="/contact">{{ $t('contact') }}</router-link></li>
      </ul>
      <div class="hamburger" @click="toggleMobileMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </nav>
    <div class="language-switcher">
      <select v-model="$i18n.locale">
        <option value="en">English</option>
        <option value="ru">Русский</option>
        <option value="de">Deutsch</option>
        <option value="es">Español</option>
        <option value="fr">Français</option>
      </select>
    </div>

    <!-- Мобильное меню -->
    <div class="mobile-menu" :class="{ 'open': isMobileMenuOpen }">
      <ul>
        <li><router-link to="/" @click="closeMobileMenu">{{ $t('home') }}</router-link></li>
        <li><router-link to="/about" @click="closeMobileMenu">{{ $t('about') }}</router-link></li>
        <li><router-link to="/services" @click="closeMobileMenu">{{ $t('services') }}</router-link></li>
        <li><router-link to="/contact" @click="closeMobileMenu">{{ $t('contact') }}</router-link></li>
      </ul>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isMobileMenuOpen: false
    };
  },
  methods: {
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false;
    }
  }
};
</script>

<style scoped>
.glass-header {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 100;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 60px;
  height: 60px;
  margin-right: 15px;
}

h1 {
  font-size: 24px;
  margin: 0;
}

.desktop-menu {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 20px;
}

.desktop-menu li a {
  color: #2d2d2d;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  transition: background 0.3s ease;
}

.desktop-menu li a:hover {
  background: rgba(255, 255, 255, 0.25);
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  cursor: pointer;
}

.hamburger span {
  display: block;
  height: 3px;
  width: 100%;
  background-color: #333;
  border-radius: 2px;
}

/* Мобильное меню */
.mobile-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 100;
  padding: 20px;
  border-radius: 15px;
}

.mobile-menu.open {
  display: block;
}

.mobile-menu ul {
  list-style-type: none;
  padding: 0;
}

.mobile-menu ul li a {
  display: block;
  color: #2d2d2d;
  text-decoration: none;
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin-bottom: 10px;
  transition: background 0.3s ease;
}

.mobile-menu ul li a:hover {
  background: rgba(255, 255, 255, 0.25);
}

/* Адаптивные стили */
@media (max-width: 768px) {
  .desktop-menu {
    display: none;
  }

  .hamburger {
    display: flex;
  }
}
</style>
