<template>
  <div class="services">
    <div class="hero">
      <h1>{{ $t('servicesTitle') }}</h1>
      <p>{{ $t('servicesDescription') }}</p>
    </div>

    <div class="service-list">
      <div class="service-item">
        <img src="@/assets/service1.png" alt="Цифровой маркетинг" class="service-icon" />
        <h2>{{ $t('service1Title') }}</h2>
        <p>{{ $t('service1Desc') }}</p>
      </div>

      <div class="service-item">
        <img src="@/assets/service2.png" alt="Разработка веб-сайтов" class="service-icon" />
        <h2>{{ $t('service2Title') }}</h2>
        <p>{{ $t('service2Desc') }}</p>
      </div>

      <div class="service-item">
        <img src="@/assets/service3.png" alt="SEO оптимизация" class="service-icon" />
        <h2>{{ $t('service3Title') }}</h2>
        <p>{{ $t('service3Desc') }}</p>
      </div>

      <div class="service-item">
        <img src="@/assets/service4.png" alt="Социальные медиа" class="service-icon" />
        <h2>{{ $t('service4Title') }}</h2>
        <p>{{ $t('service4Desc') }}</p>
      </div>

      <div class="service-item">
        <img src="@/assets/service5.png" alt="Контент-маркетинг" class="service-icon" />
        <h2>{{ $t('service5Title') }}</h2>
        <p>{{ $t('service5Desc') }}</p>
      </div>

      <div class="service-item">
        <img src="@/assets/service6.png" alt="Email маркетинг" class="service-icon" />
        <h2>{{ $t('service6Title') }}</h2>
        <p>{{ $t('service6Desc') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServicesView',
};
</script>

<style scoped>
.services {
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.hero {
  background: linear-gradient(135deg, #1c315a, #091729);
  color: white;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 30px;
}

.service-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.service-item {
  flex: 1 1 30%;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.service-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
}

h2 {
  color: #333;
  margin-bottom: 15px;
}

p {
  color: #666;
  line-height: 1.6;
}
</style>
