<template>
  <div class="about">
    <div class="hero">
      <h1>{{ $t('about') }}</h1>
      <p>{{ $t('aboutDescription') }}</p>
    </div>

    <div class="about-content">
      <section class="mission">
        <h2>{{ $t('ourMission') }}</h2>
        <p>{{ $t('missionDescription') }}</p>
      </section>

      <section class="values">
        <h2>{{ $t('ourValues') }}</h2>
        <ul>
          <li>{{ $t('valueProfessionalism') }}</li>
          <li>{{ $t('valueResponsibility') }}</li>
          <li>{{ $t('valueIndividualApproach') }}</li>
          <li>{{ $t('valueInnovation') }}</li>
        </ul>
      </section>

      <section class="team">
        <h2>{{ $t('ourTeam') }}</h2>
        <p>{{ $t('teamDescription') }}</p>
      </section>

      <section class="history">
        <h2>{{ $t('ourHistory') }}</h2>
        <p>{{ $t('historyDescription') }}</p>

        <!-- Временная шкала -->
        <div class="timeline">
          <div class="timeline-item">
            <div class="timeline-content">
              <h3>{{ $t('timeline2010') }}</h3>
              <p>{{ $t('timeline2010Description') }}</p>
            </div>
          </div>
          <div class="timeline-item">
            <div class="timeline-content">
              <h3>{{ $t('timeline2012') }}</h3>
              <p>{{ $t('timeline2012Description') }}</p>
            </div>
          </div>
          <div class="timeline-item">
            <div class="timeline-content">
              <h3>{{ $t('timeline2015') }}</h3>
              <p>{{ $t('timeline2015Description') }}</p>
            </div>
          </div>
          <div class="timeline-item">
            <div class="timeline-content">
              <h3>{{ $t('timeline2018') }}</h3>
              <p>{{ $t('timeline2018Description') }}</p>
            </div>
          </div>
          <div class="timeline-item">
            <div class="timeline-content">
              <h3>{{ $t('timeline2023') }}</h3>
              <p>{{ $t('timeline2023Description') }}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutView',
};
</script>

<style scoped>
.about {
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.hero {
  background: linear-gradient(135deg, #1c315a, #091729);
  color: white;
  padding: 40px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 30px;
}

.about-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2 {
  color: #8f8d8d;
  margin-bottom: 20px;
}

p {
  line-height: 1.6;
  margin-bottom: 15px;
  color: #666;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
  color: #666;
}

.mission, .values, .team, .history {
  margin-top: 40px;
}

.values ul {
  margin-top: 10px;
}

.timeline {
  margin-top: 40px;
  position: relative;
}

.timeline::before {
  content: '';
  position: absolute;
  width: 4px;
  background-color: #00264e;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -2px;
}

.timeline-item {
  padding: 20px;
  position: relative;
  width: 50%;
}

.timeline-item:nth-child(odd) {
  left: 0;
}

.timeline-item:nth-child(even) {
  left: 50%;
}

.timeline-item::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #00264e;
  top: 20px;
  left: -10px;
  transform: translateX(-50%);
}

.timeline-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
</style>
