<template>
  <footer class="footer">
    <div class="footer-container">
      <div class="footer-links">
        <ul>
          <li><a href="#">{{ $t('contact') }}</a></li>
          <li><a href="#">{{ $t('about') }}</a></li>
          <li><a href="#">{{ $t('services') }}</a></li>
        </ul>
      </div>
      <div class="footer-contact">
        <p>{{ $t('phone') }}: +7 (985) 882-95-97</p>
        <p>{{ $t('email') }}: info@corp-marketing-solution.com</p>
      </div>
    </div>
    <div class="footer-bottom">
      <p>&copy; 2024 Marketing Solution. {{ $t('allRightsReserved') }}</p>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  background-color: #111;
  color: #fff;
  padding: 20px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 20px;
}

.footer-links ul li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links ul li a:hover {
  color: #007bff;
}

.footer-contact p {
  margin: 5px 0;
}

.footer-bottom {
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid #333;
  margin-top: 20px;
}

/* Адаптивные стили для мобильных устройств */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-links ul {
    flex-direction: column;
    gap: 10px;
  }

  .footer-contact {
    margin-top: 15px;
  }
}
</style>
