<template>
  <div id="app">
    <AppHeader />
    <div style="flex-grow: 1;">
      <router-view />
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  components: {
    AppHeader,
    AppFooter
  }
}
</script>

<style>
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background: linear-gradient(135deg, #1c315a, #091729);
  color: #0e0d0d;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
}

#app {
  width: 100%; /* Ширина 100% для правильной адаптации */
  max-width: 1200px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 25px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  min-height: 100vh;
  position: relative;
  padding-bottom: 30px;
  box-sizing: border-box; /* Добавляем box-sizing для корректного вычисления ширины */
}

/* Меню */
.header nav ul {
  display: flex;
  gap: 20px;
}

@media (max-width: 768px) {
  /* Убираем меню и добавляем иконку бургер-меню */
  .header nav ul {
    display: none;
  }

  .burger-menu {
    display: block;
    font-size: 24px;
    cursor: pointer;
  }
}

/* Секция "Кто мы" */
.about-image {
  width: 100%; /* Картинка станет адаптивной */
  max-width: 400px; /* Ограничим максимальную ширину */
  height: auto;
  margin: 0 auto;
}

.about-text {
  text-align: center; /* Центрируем текст */
  padding: 20px;
}

/* Медиазапросы для мобильных устройств */
@media (max-width: 768px) {
  #app {
    width: 100%;
    padding: 15px;
    border-radius: 15px;
    box-shadow: none;
  }

  /* Для секции Кто мы */
  .about-content {
    flex-direction: column; /* Картинка и текст будут вертикальными */
    text-align: center;
  }

  .about-text {
    max-width: 100%; /* Убираем ограничения для текста */
  }

  /* Центрируем текст на мобильных устройствах */
  body {
    font-size: 14px; /* Уменьшаем шрифт для мобильных устройств */
  }

  .header,
  .footer {
    text-align: center; /* Центрируем текст */
  }

  #app {
    padding-bottom: 50px; /* Увеличиваем отступ внизу для мобильных устройств */
  }

  /* Подгоняем размеры и расстояния для мобильных устройств */
  h1 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
  }
}
</style>
