import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    home: 'Home',
    about: 'About Us',
    services: 'Services',
    contact: 'Contact',
    welcome: 'Welcome to Marketing Solution',
    whoWeAre: 'Who We Are',
    ourMission: 'Our Mission',
    ourValues: 'Our Values',
    ourTeam: 'Our Team',
    ourHistory: 'Our History',
    clientTrust: 'More than 350,000 clients from 183 countries choose Marketing Solution, the most user-friendly email marketing platform in the world.',
    experienceOverview: 'Based on 15 years of experience, Marketing Solution offers marketing solutions recognized by business experts as the most advanced, widespread, and fastest in terms of ensuring growth.',
    service1Title: 'Digital Marketing',
    service1Desc: 'We offer comprehensive digital marketing solutions to help you attract more customers and increase sales.',
    service2Title: 'Website Development',
    service2Desc: 'We create high-quality websites that attract attention and ensure usability.',
    service3Title: 'SEO Optimization',
    service3Desc: 'We increase your site\'s visibility in search engines, leading to increased organic traffic and sales.',
    service4Title: 'Social Media',
    service4Desc: 'We manage your social media accounts, create content, and launch advertising campaigns.',
    service5Title: 'Content Marketing',
    service5Desc: 'We develop a content marketing strategy that helps you attract and retain your target audience.',
    service6Title: 'Email Marketing',
    service6Desc: 'We create and manage effective email campaigns that help you engage with customers and increase sales.',
    address: 'Address',
    phone: 'Phone',
    email: 'Email',
    telegram: 'Telegram',
    whatsapp: 'WhatsApp',
    contactUs: 'Contact Us',
    ourLocation: 'Our Location',
    welcomeText: 'We help you reach new heights in business through our advanced marketing solutions.',
    // About Page
    aboutDescription: 'We are a team of professionals helping companies grow and achieve their goals through effective marketing strategies.',
    missionDescription: 'Our mission is to provide each client with the tools and knowledge needed to succeed in business.',
    valueProfessionalism: 'Professionalism',
    valueResponsibility: 'Responsibility',
    valueIndividualApproach: 'Individual Approach',
    valueInnovation: 'Innovation',
    teamDescription: 'Our team consists of experienced professionals in marketing, design, and development, who work together to achieve common goals.',
    historyDescription: 'We started our journey in 2010 and have been helping companies worldwide find effective solutions for their businesses ever since.',
    timeline2010: '2010',
    timeline2010Description: 'Company founded and first office opened.',
    timeline2012: '2012',
    timeline2012Description: 'Team expanded to 50 members and first international project launched.',
    timeline2015: '2015',
    timeline2015Description: 'Offices opened in the USA and Europe. Introduction of new marketing technologies.',
    timeline2018: '2018',
    timeline2018Description: 'International recognition and awards for innovation in marketing.',
    timeline2023: '2023',
    timeline2023Description: 'Achieved milestone of 1 million clients worldwide and launched new products.',
    // Services Page
    servicesTitle: 'Our Services',
    servicesDescription: 'We offer a wide range of marketing services to help your business grow and thrive.',
    // Contacts Page
    contactTitle: 'Contacts',
    contactSubtitle: 'Get in touch with us for more information or support. We are always happy to help!',
    contactViaTelegram: 'Contact via Telegram',
    contactViaWhatsapp: 'Contact via WhatsApp',
  },
  de: {
    home: 'Startseite',
    about: 'Über uns',
    services: 'Dienstleistungen',
    contact: 'Kontakt',
    welcome: 'Willkommen bei Marketing Solution',
    whoWeAre: 'Wer wir sind',
    ourMission: 'Unsere Mission',
    ourValues: 'Unsere Werte',
    ourTeam: 'Unser Team',
    ourHistory: 'Unsere Geschichte',
    clientTrust: 'Mehr als 350.000 Kunden aus 183 Ländern wählen Marketing Solution, die benutzerfreundlichste E-Mail-Marketing-Plattform der Welt.',
    experienceOverview: 'Basierend auf 15 Jahren Erfahrung bietet Marketing Solution Marketinglösungen, die von Geschäftsexperten als die fortschrittlichsten, weitverbreitetsten und schnellsten anerkannt werden, um Wachstum zu gewährleisten.',
    service1Title: 'Digitales Marketing',
    service1Desc: 'Wir bieten umfassende digitale Marketinglösungen, die Ihnen helfen, mehr Kunden zu gewinnen und den Umsatz zu steigern.',
    service2Title: 'Webseitenentwicklung',
    service2Desc: 'Wir erstellen qualitativ hochwertige Websites, die Aufmerksamkeit erregen und Benutzerfreundlichkeit gewährleisten.',
    service3Title: 'SEO-Optimierung',
    service3Desc: 'Wir erhöhen die Sichtbarkeit Ihrer Website in Suchmaschinen, was zu mehr organischem Traffic und Umsatz führt.',
    service4Title: 'Soziale Medien',
    service4Desc: 'Wir verwalten Ihre Social-Media-Konten, erstellen Inhalte und starten Werbekampagnen.',
    service5Title: 'Content-Marketing',
    service5Desc: 'Wir entwickeln eine Content-Marketing-Strategie, die Ihnen hilft, Ihre Zielgruppe anzuziehen und zu binden.',
    service6Title: 'E-Mail-Marketing',
    service6Desc: 'Wir erstellen und verwalten effektive E-Mail-Kampagnen, die Ihnen helfen, mit Kunden in Kontakt zu treten und den Umsatz zu steigern.',
    address: 'Adresse',
    phone: 'Telefon',
    email: 'E-Mail',
    telegram: 'Telegramm',
    whatsapp: 'WhatsApp',
    contactUs: 'Kontaktiere uns',
    ourLocation: 'Unser Standort',
    welcomeText: 'Wir helfen Ihnen, neue Höhen im Geschäft zu erreichen, dank unserer fortschrittlichen Marketinglösungen.',
    // About Page
    aboutDescription: 'Wir sind ein Team von Fachleuten, das Unternehmen hilft, zu wachsen und ihre Ziele durch effektive Marketingstrategien zu erreichen.',
    missionDescription: 'Unsere Mission ist es, jedem Kunden die Werkzeuge und das Wissen zur Verfügung zu stellen, die er benötigt, um im Geschäft erfolgreich zu sein.',
    valueProfessionalism: 'Professionalität',
    valueResponsibility: 'Verantwortung',
    valueIndividualApproach: 'Individueller Ansatz',
    valueInnovation: 'Innovation',
    teamDescription: 'Unser Team besteht aus erfahrenen Fachleuten in den Bereichen Marketing, Design und Entwicklung, die gemeinsam auf gemeinsame Ziele hinarbeiten.',
    historyDescription: 'Wir haben unsere Reise im Jahr 2010 begonnen und helfen seitdem Unternehmen weltweit, effektive Lösungen für ihr Geschäft zu finden.',
    timeline2010: '2010',
    timeline2010Description: 'Gründung des Unternehmens und Eröffnung des ersten Büros.',
    timeline2012: '2012',
    timeline2012Description: 'Erweiterung des Teams auf 50 Mitglieder und Start des ersten internationalen Projekts.',
    timeline2015: '2015',
    timeline2015Description: 'Eröffnung von Büros in den USA und Europa. Einführung neuer Marketingtechnologien.',
    timeline2018: '2018',
    timeline2018Description: 'Internationale Anerkennung und Auszeichnungen für Innovationen im Marketing.',
    timeline2023: '2023',
    timeline2023Description: 'Erreichen des Meilensteins von 1 Million Kunden weltweit und Einführung neuer Produkte.',
    // Services Page
    servicesTitle: 'Unsere Dienstleistungen',
    servicesDescription: 'Wir bieten eine breite Palette von Marketingdienstleistungen an, um Ihrem Unternehmen zu helfen, zu wachsen und zu gedeihen.',
    // Contacts Page
    contactTitle: 'Kontakt',
    contactSubtitle: 'Kontaktieren Sie uns für weitere Informationen oder Unterstützung. Wir helfen Ihnen gerne weiter!',
    contactViaTelegram: 'Kontaktieren Sie uns über Telegram',
    contactViaWhatsapp: 'Kontaktieren Sie uns über WhatsApp',
  },
  es: {
    home: 'Inicio',
    about: 'Sobre nosotros',
    services: 'Servicios',
    contact: 'Contacto',
    welcome: 'Bienvenido a Marketing Solution',
    whoWeAre: 'Quiénes somos',
    ourMission: 'Nuestra misión',
    ourValues: 'Nuestros valores',
    ourTeam: 'Nuestro equipo',
    ourHistory: 'Nuestra historia',
    clientTrust: 'Más de 350,000 clientes de 183 países eligen Marketing Solution, la plataforma de marketing por correo electrónico más fácil de usar del mundo.',
    experienceOverview: 'Con base en 15 años de experiencia, Marketing Solution ofrece soluciones de marketing reconocidas por expertos empresariales como las más avanzadas, extendidas y rápidas en términos de garantizar el crecimiento.',
    service1Title: 'Marketing digital',
    service1Desc: 'Ofrecemos soluciones integrales de marketing digital que le ayudarán a atraer a más clientes y aumentar las ventas.',
    service2Title: 'Desarrollo de sitios web',
    service2Desc: 'Creamos sitios web de alta calidad que atraen la atención y garantizan la facilidad de uso.',
    service3Title: 'Optimización SEO',
    service3Desc: 'Aumentamos la visibilidad de su sitio en los motores de búsqueda, lo que conduce a un aumento del tráfico orgánico y de las ventas.',
    service4Title: 'Redes sociales',
    service4Desc: 'Gestionamos sus cuentas de redes sociales, creamos contenido y lanzamos campañas publicitarias.',
    service5Title: 'Marketing de contenidos',
    service5Desc: 'Desarrollamos una estrategia de marketing de contenidos que le ayudará a atraer y retener a su público objetivo.',
    service6Title: 'Marketing por correo electrónico',
    service6Desc: 'Creamos y gestionamos campañas de correo electrónico efectivas que le ayudarán a interactuar con los clientes y aumentar las ventas.',
    address: 'Dirección',
    phone: 'Teléfono',
    email: 'Correo electrónico',
    telegram: 'Telegrama',
    whatsapp: 'WhatsApp',
    contactUs: 'Contáctenos',
    ourLocation: 'Nuestra ubicación',
    welcomeText: 'Le ayudamos a alcanzar nuevas alturas en los negocios a través de nuestras soluciones avanzadas de marketing.',
    // About Page
    aboutDescription: 'Somos un equipo de profesionales que ayudan a las empresas a crecer y alcanzar sus objetivos mediante estrategias de marketing efectivas.',
    missionDescription: 'Nuestra misión es proporcionar a cada cliente las herramientas y conocimientos necesarios para tener éxito en los negocios.',
    valueProfessionalism: 'Profesionalismo',
    valueResponsibility: 'Responsabilidad',
    valueIndividualApproach: 'Enfoque individual',
    valueInnovation: 'Innovación',
    teamDescription: 'Nuestro equipo está formado por profesionales experimentados en marketing, diseño y desarrollo, que trabajan juntos para lograr objetivos comunes.',
    historyDescription: 'Comenzamos nuestro viaje en 2010 y desde entonces hemos ayudado a empresas de todo el mundo a encontrar soluciones efectivas para sus negocios.',
    timeline2010: '2010',
    timeline2010Description: 'Fundación de la empresa y apertura de la primera oficina.',
    timeline2012: '2012',
    timeline2012Description: 'Expansión del equipo a 50 miembros y lanzamiento del primer proyecto internacional.',
    timeline2015: '2015',
    timeline2015Description: 'Apertura de oficinas en EE. UU. y Europa. Introducción de nuevas tecnologías de marketing.',
    timeline2018: '2018',
    timeline2018Description: 'Reconocimiento internacional y premios por innovación en marketing.',
    timeline2023: '2023',
    timeline2023Description: 'Logro del hito de 1 millón de clientes en todo el mundo y lanzamiento de nuevos productos.',
    // Services Page
    servicesTitle: 'Nuestros Servicios',
    servicesDescription: 'Ofrecemos una amplia gama de servicios de marketing para ayudar a su empresa a crecer y prosperar.',
    // Contacts Page
    contactTitle: 'Contacto',
    contactSubtitle: 'Póngase en contacto con nosotros para obtener más información o asistencia. ¡Estamos siempre dispuestos a ayudar!',
    contactViaTelegram: 'Contactar a través de Telegram',
    contactViaWhatsapp: 'Contactar a través de WhatsApp',
  },
  fr: {
    home: 'Accueil',
    about: 'À propos de nous',
    services: 'Services',
    contact: 'Contact',
    welcome: 'Bienvenue chez Marketing Solution',
    whoWeAre: 'Qui nous sommes',
    ourMission: 'Notre mission',
    ourValues: 'Nos valeurs',
    ourTeam: 'Notre équipe',
    ourHistory: 'Notre histoire',
    clientTrust: 'Plus de 350 000 clients de 183 pays choisissent Marketing Solution, la plateforme de marketing par e-mail la plus conviviale au monde.',
    experienceOverview: 'Fort de 15 ans d\'expérience, Marketing Solution propose des solutions marketing reconnues par les experts du secteur comme les plus avancées, répandues et rapides pour assurer la croissance.',
    service1Title: 'Marketing numérique',
    service1Desc: 'Nous proposons des solutions complètes de marketing numérique pour vous aider à attirer plus de clients et à augmenter les ventes.',
    service2Title: 'Développement de sites Web',
    service2Desc: 'Nous créons des sites Web de haute qualité qui attirent l\'attention et assurent une utilisation facile.',
    service3Title: 'Optimisation SEO',
    service3Desc: 'Nous augmentons la visibilité de votre site dans les moteurs de recherche, ce qui entraîne une augmentation du trafic organique et des ventes.',
    service4Title: 'Réseaux sociaux',
    service4Desc: 'Nous gérons vos comptes de réseaux sociaux, créons du contenu et lançons des campagnes publicitaires.',
    service5Title: 'Marketing de contenu',
    service5Desc: 'Nous développons une stratégie de marketing de contenu qui vous aide à attirer et à fidéliser votre public cible.',
    service6Title: 'Email marketing',
    service6Desc: 'Nous créons et gérons des campagnes d\'emailing efficaces qui vous aident à interagir avec les clients et à augmenter les ventes.',
    address: 'Adresse',
    phone: 'Téléphone',
    email: 'Email',
    telegram: 'Télégramme',
    whatsapp: 'WhatsApp',
    contactUs: 'Nous contacter',
    ourLocation: 'Notre emplacement',
    welcomeText: 'Nous vous aidons à atteindre de nouveaux sommets en affaires grâce à nos solutions de marketing avancées.',
    // About Page
    aboutDescription: 'Nous sommes une équipe de professionnels aidant les entreprises à se développer et à atteindre leurs objectifs grâce à des stratégies de marketing efficaces.',
    missionDescription: 'Notre mission est de fournir à chaque client les outils et les connaissances nécessaires pour réussir en affaires.',
    valueProfessionalism: 'Professionnalisme',
    valueResponsibility: 'Responsabilité',
    valueIndividualApproach: 'Approche individuelle',
    valueInnovation: 'Innovation',
    teamDescription: 'Notre équipe se compose de professionnels expérimentés en marketing, design et développement, qui travaillent ensemble pour atteindre des objectifs communs.',
    historyDescription: 'Nous avons commencé notre parcours en 2010 et depuis lors, nous aidons des entreprises du monde entier à trouver des solutions efficaces pour leurs activités.',
    timeline2010: '2010',
    timeline2010Description: 'Fondation de l\'entreprise et ouverture du premier bureau.',
    timeline2012: '2012',
    timeline2012Description: 'Expansion de l\'équipe à 50 membres et lancement du premier projet international.',
    timeline2015: '2015',
    timeline2015Description: 'Ouverture de bureaux aux États-Unis et en Europe. Introduction de nouvelles technologies de marketing.',
    timeline2018: '2018',
    timeline2018Description: 'Reconnaissance internationale et prix pour l\'innovation en marketing.',
    timeline2023: '2023',
    timeline2023Description: 'Atteinte du cap de 1 million de clients dans le monde entier et lancement de nouveaux produits.',
    // Services Page
    servicesTitle: 'Nos Services',
    servicesDescription: 'Nous proposons une large gamme de services marketing pour aider votre entreprise à se développer et à prospérer.',
    // Contacts Page
    contactTitle: 'Contacts',
    contactSubtitle: 'Contactez-nous pour plus d\'informations ou de support. Nous sommes toujours heureux de vous aider!',
    contactViaTelegram: 'Contactez via Telegram',
    contactViaWhatsapp: 'Contactez via WhatsApp',
  },
  ru: {
    home: 'Главная',
    about: 'О нас',
    services: 'Услуги',
    contact: 'Контакты',
    welcome: 'Добро пожаловать в Marketing Solution',
    whoWeAre: 'Кто мы',
    ourMission: 'Наша миссия',
    ourValues: 'Наши ценности',
    ourTeam: 'Наша команда',
    ourHistory: 'Наша история',
    clientTrust: 'Более чем 350,000 клиентов на 183 страны выбирают Marketing Solution, самую удобную платформу для Email маркетинга в мире.',
    experienceOverview: 'Опираясь на 15-летний опыт, Marketing Solution предлагает решения Internet маркетинга, признанные экспертами бизнеса как наиболее прогрессивные, распространенные и быстрые в плане обеспечения роста.',
    service1Title: 'Цифровой маркетинг',
    service1Desc: 'Мы предлагаем комплексные решения для цифрового маркетинга, которые помогут вам привлечь больше клиентов и увеличить продажи.',
    service2Title: 'Разработка веб-сайтов',
    service2Desc: 'Создаем высококачественные веб-сайты, которые привлекают внимание и обеспечивают удобство использования.',
    service3Title: 'SEO оптимизация',
    service3Desc: 'Повышаем видимость вашего сайта в поисковых системах, что приводит к увеличению органического трафика и продаж.',
    service4Title: 'Социальные медиа',
    service4Desc: 'Управляем вашими аккаунтами в социальных сетях, создаем контент и запускаем рекламные кампании.',
    service5Title: 'Контент-маркетинг',
    service5Desc: 'Разрабатываем стратегию контент-маркетинга, которая поможет вам привлечь и удержать вашу целевую аудиторию.',
    service6Title: 'Email маркетинг',
    service6Desc: 'Создаем и управляем эффективными email-кампаниями, которые помогут вам взаимодействовать с клиентами и увеличивать продажи.',
    address: 'Адрес',
    phone: 'Телефон',
    email: 'Электронная почта',
    telegram: 'Телеграм',
    whatsapp: 'WhatsApp',
    contactUs: 'Свяжитесь с нами',
    ourLocation: 'Наше местоположение',
    welcomeText: 'Мы помогаем вам достигать новых вершин в бизнесе благодаря нашим передовым маркетинговым решениям.',
    // About Page
    aboutDescription: 'Мы — команда профессионалов, которая помогает компаниям развиваться и достигать своих целей с помощью эффективных маркетинговых стратегий.',
    missionDescription: 'Наша миссия — предоставить каждому клиенту инструменты и знания, необходимые для достижения успеха в бизнесе.',
    valueProfessionalism: 'Профессионализм',
    valueResponsibility: 'Ответственность',
    valueIndividualApproach: 'Индивидуальный подход',
    valueInnovation: 'Инновации',
    teamDescription: 'Наша команда состоит из опытных специалистов в области маркетинга, дизайна и разработки, которые объединяют свои усилия для достижения общих целей.',
    historyDescription: 'Мы начали свой путь в 2010 году и с тех пор помогаем компаниям по всему миру находить эффективные решения для их бизнеса.',
    timeline2010: '2010',
    timeline2010Description: 'Основание компании и запуск первого офиса.',
    timeline2012: '2012',
    timeline2012Description: 'Расширение команды до 50 человек и запуск первого международного проекта.',
    timeline2015: '2015',
    timeline2015Description: 'Открытие офисов в США и Европе. Внедрение новых технологий в маркетинге.',
    timeline2018: '2018',
    timeline2018Description: 'Признание на международном уровне и получение премий за инновации в области маркетинга.',
    timeline2023: '2023',
    timeline2023Description: 'Достижение рубежа в 1 миллион клиентов по всему миру и запуск новых продуктов.',
    // Services Page
    servicesTitle: 'Наши услуги',
    servicesDescription: 'Мы предлагаем широкий спектр маркетинговых услуг, чтобы помочь вашему бизнесу расти и развиваться.',
    // Contacts Page
    contactTitle: 'Контакты',
    contactSubtitle: 'Свяжитесь с нами для получения дополнительной информации или поддержки. Мы всегда рады помочь!',
    contactViaTelegram: 'Связаться через Telegram',
    contactViaWhatsapp: 'Связаться через WhatsApp',
  },
};

const i18n = createI18n({
  locale: 'ru', // Язык по умолчанию
  fallbackLocale: 'en', // Резервный язык
  messages,
});

export default i18n;
